import { Pie } from '@ant-design/plots';
import { MapboxScene, PointLayer, PolygonLayer } from '@antv/l7-react';
import axios from 'axios';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const GreenFinance = () => {
  const [filldata, setfillData] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const geoData = await axios.get('/map/e62a2f3b-ea99-4c98-9314-01d7c886263d.json', {
        responseType: 'json',
      });
      setfillData(geoData.data);
    };
    fetchData();
  }, []);
  return (
    <Layout>
      <Seo
        title="Green Finance | Sustainability for both Environment and Business"
        description="Get a bird's eye view of the risks and areas of improvement in supply chain sustainability. Visualize areas of competitive advantage with industry peers"
      />
      <main className="main-wrapper">
        <HeaderCommon />

        <BreadcrumbSection
          title={
            <span>
              Good For Environment, <br />
              Good For Business
            </span>
          }
          paragraph={
            <span>
              Sustainability is becoming more important for all companies, across all industries.
              62% of executives considers a sustainability strategy necessary to be competitive
              today. Sustainability is a strategic business approach to create long term value for
              the company. <br />
              <br />
              Making sustainability a key strategy of the organisation, not just yields better
              performances and presents a more attractive value proposition to investors and
              customers, it also differentiates you from your competition and reduces the potential
              reputational damage to the brand.
            </span>
          }
        />

        {/* GreenFinance */}

        <div className="section green-finance-wrap">
          <div className="container">
            <div className="btn-wrap">
              <Link className="banco-btn btn-fill-primary" to="/contact">
                Speak with our team
              </Link>
            </div>
            <div className="content-2 row">
              <div className="col col-mobile">
                <p className="-title">
                  Sustainable and
                  <br /> Green Finance
                </p>
              </div>
              <div className="col-5">
                <img className="light-mode" src="/images/green-finance-1.png" />
              </div>
              <div className="col">
                <p className="-title">Sustainable and Green Finance</p>
                <p className="-description">
                  By incorporating Supply Chain Finance with sustainability assessment allows
                  financial institutions to easily categorise companies based on their
                  sustainability index and extend more favourable green financing rate to them. In
                  turn, incentivising more companies to go green.
                </p>
              </div>
            </div>
            <div className="content-2 row">
              <div className="col">
                <p className="-title">Monitor your Green Performance</p>
                <p className="-description">
                  Real time supply chain insights on sustainability based on GRI Reporting
                  Standards. Increasing regulatory requirements and stakeholders demand are forcing
                  companies to be conscious of sustainability reporting.
                </p>
              </div>
              <div className="col-5">
                <img className="light-mode" src="/images/green-finance-2.png" />
              </div>
              <div className="col col-mobile">
                <p className="-title">
                  Monitor your Green
                  <br /> Performance
                </p>
              </div>
            </div>
            <div className="content-2 row">
              <div className="col col-mobile">
                <p className="-title">
                  Go Green
                  <br /> with your Suppliers
                </p>
              </div>
              <div className="col-5">
                <img className="light-mode" src="/images/green-finance-3.png" />
              </div>
              <div className="col">
                <p className="-title">Go Green with your Suppliers</p>
                <p className="-description">
                  Incorporating sustainability elements into the deep-tier supply chain would
                  provide visibility and transparency to the compliance on sustainability for
                  suppliers down the chain. Through supply chain finance, it incentivises and
                  rewards suppliers to adopt more sustainable activities or source for alternative
                  materials, and for them to be more careful in the evaluation of their suppliers.
                </p>
              </div>
            </div>

            <p className="-breadcrumb">Discover Hidden Risks and Opportunities</p>
            <p className="-sub-breadcrumb">
              Get a bird’s eye view of the risks and areas of improvement in sustainability for your
              supply chain. Visualise areas of competitive advantage and comparison with industry
              peers.
            </p>

            <div className="row green-finace-charts">
              <div className="col-lg-4">
                <div className="title">
                  <h3>Scoring across four Dimensions</h3>
                </div>
                <div className="chart-wrap">
                  <Pie
                    data={[
                      {
                        type: 'Economic',
                        value: 33.1,
                      },
                      {
                        type: 'Governance',
                        value: 29.1,
                      },
                      {
                        type: 'Environmental',
                        value: 21.5,
                      },
                      {
                        type: 'Social',
                        value: 16.3,
                      },
                    ]}
                    angleField="value"
                    colorField="type"
                    radius={0.8}
                    innerRadius={0.5}
                    label={{
                      type: 'outer',
                      labelHeight: 28,
                      labelLine: false,
                      content: '{name}\n{percentage}',
                      style: {
                        fill: '#272727',
                        opacity: 1,
                        fontSize: 10,
                        fontWeight: 400,
                      },
                    }}
                    legend={false}
                    statistic={{
                      title: false,
                      content: false,
                    }}
                    appendPadding={0}
                    theme={{
                      colors10: [
                        '#d23229',
                        '#56275d',
                        '#2b4ebb',
                        '#f7a53c',
                        '#981e61',
                        '#110055',
                        '#251f7b',
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="title">
                  <h3>Respondents by sector</h3>
                </div>
                <div className="chart-wrap">
                  <Pie
                    data={[
                      {
                        type: 'Automotive',
                        value: 20,
                      },
                      {
                        type: 'Chemicals',
                        value: 20,
                      },
                      {
                        type: 'Logistics',
                        value: 6,
                      },
                      {
                        type: 'Energy',
                        value: 14,
                      },
                      {
                        type: 'Construction',
                        value: 20,
                      },
                      {
                        type: 'Metal products',
                        value: 10,
                      },
                      {
                        type: 'Textile',
                        value: 10,
                      },
                    ]}
                    angleField="value"
                    colorField="type"
                    radius={0.8}
                    innerRadius={0.5}
                    label={{
                      type: 'outer',
                      labelHeight: 28,
                      labelLine: false,
                      content: '{name}\n{percentage}',
                      style: {
                        fill: '#272727',
                        opacity: 1,
                        fontSize: 10,
                        fontWeight: 400,
                      },
                    }}
                    legend={false}
                    statistic={{
                      title: false,
                      content: false,
                    }}
                    appendPadding={0}
                    theme={{
                      colors10: [
                        '#d23229',
                        '#56275d',
                        '#2b4ebb',
                        '#f7a53c',
                        '#981e61',
                        '#110055',
                        '#251f7b',
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="title">
                  <h3>Implementation of sustainability practices to the business strategy</h3>
                </div>
                <div className="chart-wrap">
                  <Pie
                    data={[
                      {
                        type: 'Implemented',
                        value: 37.3,
                      },
                      {
                        type: 'Not interested',
                        value: 28.8,
                      },
                      {
                        type: 'Planning to',
                        value: 33.9,
                      },
                    ]}
                    angleField="value"
                    colorField="type"
                    radius={0.8}
                    innerRadius={0}
                    label={{
                      type: 'outer',
                      labelHeight: 28,
                      labelLine: false,
                      content: '{name}\n{percentage}',
                      style: {
                        fill: '#272727',
                        opacity: 1,
                        fontSize: 10,
                        fontWeight: 400,
                      },
                    }}
                    legend={false}
                    statistic={{
                      title: false,
                      content: false,
                    }}
                    appendPadding={0}
                    theme={{
                      colors10: ['#971562', '#d13228', '#f35451'],
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Geographical scope of business operation */}
            <p className="-breadcrumb">Geographical scope of business operation</p>
            <div
              style={{
                height: 485,
                position: 'relative',
              }}
            >
              <div className="geoWrap">
                <ul className="list-unstyled list-color-index">
                  <li>
                    <span
                      style={{
                        background: '#FF1616',
                      }}
                    />
                    Singapore
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#FF5757',
                      }}
                    />
                    Malaysia
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#FF66C4',
                      }}
                    />
                    Indonasia
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#CA6BE6',
                      }}
                    />
                    Thailand
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#8B51FF',
                      }}
                    />
                    Europe
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#5E17EA',
                      }}
                    />
                    China
                  </li>
                  <li>
                    <span
                      style={{
                        background: '#2B017D',
                      }}
                    />
                    Africa
                  </li>
                </ul>
              </div>
              <MapboxScene
                map={{
                  style: 'blank',
                  center: [-2.2872564, 8.4959647],
                  zoom: 1,
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                option={{
                  logoVisible: false,
                }}
              >
                {filldata && [
                  <PolygonLayer
                    key={'1'}
                    options={{
                      autoFit: false,
                    }}
                    source={{
                      data: filldata,
                    }}
                    scale={{
                      values: {
                        confirmedCount: {
                          type: 'quantile',
                        },
                      },
                    }}
                    color={{
                      values: '#a4a4a4',
                    }}
                    shape={{
                      values: 'fill',
                    }}
                    style={{
                      opacity: 1,
                    }}
                  />,
                  <PointLayer
                    source={{
                      data: [
                        {
                          country: 'Europe',
                          lng: 46.6469714,
                          lat: 52.4997102,
                          level: 5,
                        },
                        {
                          country: 'Africa',
                          lng: 16.0347,
                          lat: 2.2241,
                          level: 4,
                        },
                        {
                          country: 'China',
                          lng: 116.3,
                          lat: 39.9,
                          level: 5,
                        },

                        {
                          country: 'Indonasia',
                          lng: 106.5044,
                          lat: 12.3155,
                          level: 3,
                        },
                        {
                          country: 'Malaysia',
                          lng: 101,
                          lat: 3,
                          level: 2,
                        },
                        {
                          country: 'Singapore',
                          lng: 103.51,
                          lat: 1.18,
                          level: 1,
                        },
                      ],
                      parser: {
                        type: 'json',
                        x: 'lng',
                        y: 'lat',
                      },
                    }}
                    size={{
                      field: 'level',
                      values: (level) => {
                        switch (level) {
                          case 5:
                            return 40;
                          case 4:
                            return 31;
                          case 3:
                            return 24;
                          case 2:
                            return 20;
                          default:
                            return 16;
                        }
                      },
                    }}
                    shape={{
                      values: 'circle',
                    }}
                    color={{
                      field: 'country',
                      values: (country) => {
                        switch (country) {
                          case 'China':
                            return '#5E17EA';
                          case 'Europe':
                            return '#8B51FF';
                          case 'Africa':
                            return '#2B017D';
                          case 'Indonasia':
                            return '#FF66C4';
                          case 'Thailand':
                            return '#CA6BE6';
                          case 'Malaysia':
                            return '#FF5757';
                          default:
                            return '#FF1616';
                        }
                      },
                    }}
                  />,
                ]}
              </MapboxScene>
            </div>

            <p className="-breadcrumb">Our Sustainability Partners</p>
            <div className="row content-3 mb--60">
              <div className="col">
                <img className="light-mode" src="/images/icon-global.png" style={{ width: 141 }} />
                <div>
                  <p className="-description">
                    Established in Singapore in 2006, Global Initiatives (GI) offers corporates and
                    institutions a range of services to achieve their sustainability goals. With
                    businesses seeking better interventions to reach their sustainability goal, GI*
                    will be introducing new data-driven solutions while continuing to focus on our
                    existing initiatives.
                  </p>
                  <p className="-remark">* GI is the organiser of Sustainable Business Awards</p>
                </div>
              </div>
              <div className="col">
                <img className="light-mode" src="/images/icon-sba.png" style={{ width: 172 }} />
                <div>
                  <p className="-description">
                    The Sustainable Business Awards (SBA) is arguably Asia’s only award series run
                    by a firm wholly committed to sustainability and which comprehensively assess
                    corporate sustainability programs. Since 2012, the Awards has been held in
                    partnership with PricewaterhouseCoopers (PwC), industry associations and
                    government agencies recognising corporate leadership in 12 categories, including
                    sustainability strategy, community, energy and water management, supply chain,
                    and ethics among others. Past winners include UOB, DBS, OLAM, Ericsson, Singtel,
                    Cargill, Modelez, Nestle, Maybank, Standard Chartered, Banyan Tree Hotel &
                    Resorts, City Development Limited, Keppel Corporate, Unilever, Bayer and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </Layout>
  );
};

export default GreenFinance;
